.profile-container {
  width: 100%;
  display: block;
  .common-service-tabs {
    width: 100%;
    display: block;
    .inner-tabs-list {
      display: block;
      width: 100%;

      .tab-list {
        display: inline-block;
        margin-bottom: -6px;
        .tab-button {
          font-size: 16px;
          text-transform: capitalize;

          &.Mui-selected {
            color: #434bdf;
          }
        }
      }
    }
    .MuiTabPanel-root {
      padding: 0;
     
    }
  }
  .information-card {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    height: 77vh;
   
    .inner-content {
      // min-height: 517px;
      // .title {
      //   display: block;
      //   padding-left: 16px;
      //   padding-right: 16px;
      //   margin-left: -16px;
      //   margin-right: -16px;
      //   h5 {
      //     font-size: 20px;
      //     margin-bottom: 16px;
      //   }
      // }

      form {
        .form-group {
          position: relative;
          .profile-box {
            width: 65px;
            height: 65px;
            color: #434bdf;
            border: 2px solid #434bdf;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              font-size: 35px;
            }
          }
          label {
            width: 100%;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 400;
            color: #000000;
            margin-bottom: 5px;
            text-align: left;
          }
          .profile-update-btn {
            position: absolute;
            top: 35px;
            left: 52%;
            background: #434bdf;
            font-size: 12px;
            color: #ffffff;
            .camera-icon {
              font-size: 18px;
            }
          }

          .form-control {
            border: 1px solid #d2d8dd;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            border-radius: 3px;
            font-size: 14px;
            font-size: 0.875rem;
            height: calc(2.55rem + 0px);
            &:focus {
              border-color: #434bdf;
              box-shadow: none;
            }
          }
          span {
            font-size: 13px;
            line-height: 1.5;
            font-weight: 400;
            color: red;
            display: block;
            text-align: left;
          }
          i {
            position: absolute;
            top: 41.5px;
            right: 10px;
            color: #858789;
            cursor: pointer;
          }
        }
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .btn-primary {
        display: block;
        min-width: 180px;
        margin-right: 15px;
      }
      .btn-primary-error {
        display: block;
        min-width: 180px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .profile-container {
    .information-card {
      width: 100%;
      height: 100%;
      min-height: 100%;
      .inner-content {
        width: 100%;
      }
      .buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;

        .btn-primary {
          display: block;
          width: 100%;
         margin-right: 0;
          margin-bottom: 15px;
        }
        .btn-primary-error {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
// @media only screen and (max-width: 575px) {
//   .profile-container {
//     .information-card {
//       .buttons {
//         .btn-primary {
//           display: block;
//           width: 100%;
        
//         }
//         .btn-primary-error {
//           display: block;
//           width: 100%;
//         }
//       }
//     }
//   }
// }
