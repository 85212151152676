.view-questionnaires {
  width: 100%;
  display: block;
  .survey-container {
    padding-bottom: 60px;
  }

  .survey-description-box {
    width: 25%;
    height: 150px;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid #d2d8dd;
    background: #fdfefe;
    border-radius: 3px;
    padding: 12px;
    .survey-description {
      margin-bottom: 8px;
    }
  }
  .comparative-table {
    overflow-x: auto;
    width: 100%;

    .table {
      min-width: 950px;
      width: 100%;
      background-color: #ffffff;
      margin: 0;
      padding: 0px 5px;
      border: 1px solid #dee2e6;
      border-collapse: collapse;

      tr {
        display: flex;
      }

      th {
        border-top: none;
        padding: 10px 10px;
        vertical-align: middle;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        color: #333951;
        width: 100%;
        display: block;
        border-right: 1px solid #dee2e6;
        border-collapse: collapse;
        &:last-child {
          border-right: none;
        }
      }

      td {
        border-right: 1px solid #dee2e6;
        border-collapse: collapse;
        padding: 5px 5px;
        white-space: nowrap;
        width: 100%;
        display: block;
        &:last-child {
          border-right: none;
        }
        .radio-buttons {
          span {
            &::before {
              width: 27px;
              height: 27px;
              content: "";
              background-image: radial-gradient(
                #434bdf,
                #434bdf,
                40%,
                transparent 44%
              );
            }
          }
        }

        .MuiIconButton-root {
          padding: 0;

          .MuiIconButton-label {
            span {
              width: 17px;
              height: 17px;

              &:before {
                width: 17px;
                height: 17px;
              }
            }

            input {
              width: 17px;
              height: 17px;
            }
          }
        }

        input {
          width: 100%;
          border: none;
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
          height: 100%;
          padding: 0;
          font-family: "Open Sans", sans-serif;
          text-align: center;
          background-color: transparent;
        }
      }
    }
  }

  .description-box {
    background: #ffffff;
    border: 1px solid #c8ccd0c2;
    border-radius: 3px;
    width: 100%;
    padding: 10px;
    min-height: 75px;

    h3 {
      color: #000000;
      font-size: 17px;
      line-height: 20px;
    }

    p {
      color: #000000;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 0;
    }
  }
  .select-choice-container{
    .form-group{
      .autocomplete-box{
        .MuiInputBase-formControl{
          height: 48px;
          .MuiOutlinedInput-notchedOutline {
            border-radius: 3px;
            border-width: 2px;
            border-color: #c8ccd0c2;
          }
          .MuiSvgIcon-root {
            color: #c8ccd0c2;
          }
        }
      }
    }
  }

  h3 {
    display: block;
    color: #000000;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 600;
    + span {
      display: block;
      font-size: 16px;
      color: #000000;
    }
  }
  .input-choice-container {
    display: block;
    .MuiInput-root {
      width: 100%;
    }
    .form-group {
      .form-control {
        background: transparent;
        width: 100%;
        height: 48px;
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        padding: 10px 15px 10px 15px;
        cursor: pointer;
        font-family: "Open Sans", sans-serif;
        .MuiOutlinedInput-notchedOutline {
          border-color: #c8ccd0c2 !important;
        }

        .MuiInputBase-input {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
          padding-top: 0;
          padding-bottom: 0;
          line-height: 1.5;
          cursor: pointer;
        }
      }
    }
  }
  .checkbox {
    display: flex;
    width: 100%;
    border-radius: 3px;
    align-items: center;
    cursor: pointer;
    border-color: #c8ccd0c2 !important;
    margin-bottom: 10px !important;
    position: relative;
    .common-check-box {
      position: absolute;
      top: 4px;
      left: 5px;
      z-index: 10;
      .MuiCheckbox-root {
        color: #434bdf;
      }

      .Mui-checked {
        color: #434bdf !important;
      }
      span {
        width: 22px !important;
        height: 22px !important;
        min-width: 22px !important;
        accent-color: #434bdf;

        &::before {
          width: 22px !important;
          height: 22px !important;
        }
      }
      .MuiTouchRipple-root {
        display: none;
      }
    }

    .form-control {
      background: transparent;
      width: 100%;
      height: 48px;
      font-size: 16px;
      color: #000000;
      font-weight: 600;
      padding: 10px 10px 10px 45px;
      cursor: pointer;
      font-family: "Open Sans", sans-serif;
      .MuiOutlinedInput-notchedOutline {
        border-color: #c8ccd0c2 !important;
      }

      .MuiInputBase-input {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.5;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover {
      background-color: #f5f7f9;
    }
  }
  .radio {
    //border: 1px solid #c8ccd0c2;
    min-height: 48px;
    border-radius: 3px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px !important;
    position: relative;
    .radio-buttons {
      position: absolute;
      top: 0;
      left: 4px;
      bottom: 0;
      z-index: 0;
    }
    .form-control {
      border: none;
      background: transparent;
      width: 100%;
      min-height: 48px;

      font-size: 16px;
      color: #000000;
      font-weight: 600;
      padding: 10px 10px 10px 50px;
      cursor: pointer;
      font-family: "Open Sans", sans-serif;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;

      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
        border-color: #c8ccd0c2 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .MuiInputBase-input {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.5;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    &:hover {
      background-color: #f5f7f9;
    }

    .MuiIconButton-label {
      span {
        width: 17px;
        height: 17px;

        &:before {
          width: 17px;
          height: 17px;
        }
      }
    }

    input {
      cursor: pointer;
      border: none;
      background: transparent;
      padding-bottom: 3px;
      width: 90%;
      font-size: 16px;
      color: #333951;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }
  .linescale-container {
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 32px;
    position: relative;
    .MuiSlider-root {
      padding: 16px 0;
      color: #434bdf;
      height: 3px;
      .MuiSlider-rail {
        opacity: 0.65;
      }
      .MuiSlider-valueLabel {
        background-color: #434bdf;
      }
    }

    .weak-line-container {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0px;

      .weak-text {
        width: auto;
        font-size: 16px;
        line-height: 14px;
        color: #313348;
        margin-top: -20px;
        margin-bottom: 5px;
        margin-left: -20px;
      }

      .line {
        width: auto;
        text-align: left;

        span {
          height: 36px;
          width: 3px;
          content: "";
          display: inline-block;
          background-color: #434bdf;
        }
      }

      .number {
        text-align: left;
        width: auto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
        margin-left: -5px;
      }
    }
    .strong-line-container {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0px;

      .strong-text {
        width: auto;
        font-size: 16px;
        line-height: 14px;
        color: #000000;
        margin-top: -20px;
        margin-bottom: 5px;
        margin-right: -20px;
        text-align: right;
      }

      .line {
        width: auto;
        text-align: right;

        span {
          height: 36px;
          width: 3px;
          content: "";
          display: inline-block;
          background-color: #434bdf;
        }
      }

      .number {
        text-align: right;
        width: auto;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        color: #000000;
        margin-right: -10px;
      }
    }
  }
  .hedonic-container {
    .heading {
      padding-bottom: 5px;

      span {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #2e2c2c;
        font-family: "Open Sans", sans-serif;
      }
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        text-align: center;
        font-size: 16px;
        color: #2e2c2c;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        line-height: 1.5;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 3px;
        list-style: none;
        border: 1px solid #c8ccd0c2;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .rating-question {
    .rating {
      margin-right: 10px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      .icon {
        width: 36px;
        height: 36px;

        &.click {
          color: #efa21c;
        }

        color: #dedede;

        svg {
          width: 36px;
          height: 36px;
        }
      }

      strong {
        display: block;
        color: #333951;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        font-family: "Open Sans", sans-serif;
      }
    }
    .rating-icon {
      span {
        display: inline;
        flex-wrap: wrap;
        label {
          margin-right: 15px;
          margin-bottom: 0;
          span {
            .MuiSvgIcon-root {
              width: 2em;
              height: 2em;
            }
          }
        }
        span {
          .MuiSvgIcon-root {
            width: 2em;
            height: 2em;
            color: #efa21c;
          }
        }
      }
    }
  }
  .jar-text {
    cursor: pointer;
    padding: 10px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #2e2c2c;
    line-height: 18px;
    font-weight: 600;
    border-radius: 3px;
    font-family: "Open Sans", sans-serif;
    border: 1px solid #c8ccd0c2;
  }
  .survey-buttons {
    width: 100%;
    text-align: end;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 20px;
    background-color: #ffffff;
    padding-bottom: 20px;
    z-index: 100;
    .btn-primary-outlined {
      width: 180px;
      height: 40px;
    }
    .btn-primary {
      width: 180px;
      height: 40px;
    }
    .Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
  .start-btn {
    width: 100%;
    text-align: end;
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 20px;
    background-color: #ffffff;
    padding-bottom: 20px;
    z-index: 100;
    .btn-primary {
      width: 180px;
      height: 40px;
    }
  }

  .date-picker-container {
    .form-control {
      .MuiOutlinedInput-notchedOutline {
        border-color: #c8ccd0c2 !important;
      }
      .MuiInputBase-root {
        .MuiInputBase-input {
          background: transparent;
          width: 100%;
          height: 48px;
          font-size: 16px;
          color: #000000;
          font-weight: 600;
          padding: 0px 10px;
          cursor: pointer;
          font-family: "Open Sans", sans-serif;
        }
      }
    }
  }
  .autocomplete {
    .form-control {
      padding: 0;
      .MuiOutlinedInput-root {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .view-questionnaires {
    .survey-description-box {
      width: 50%;
      height: 150px;
    }
    .start-btn {
      text-align: center;
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .view-questionnaires {
    .survey-description-box {
      width: 100%;
      height: 150px;
    }
    .start-btn {
      text-align: center;
      position: fixed;
      width: 90%;
      bottom: 0;
      z-index: 100;
      margin: 0 auto;
      .btn-primary {
        width: 100%;
      }
    }
    .survey-buttons {
      text-align: center;
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      .btn-primary-outlined {
        width: 150px;
      }
      .btn-primary {
        width: 150px;
      }
    }
    .select-choice-container{
      .form-group{
        .autocomplete-box{
          .MuiInputBase-formControl{
            height: auto;
            
          }
        }
      }
    }
  }
  .radio {
    .form-control {
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-wrap;
     
    }
  }
}
