.update-information-container {
  width: 100%;
  display: block;
  .common-service-tabs {
    width: 100%;
    display: block;
    .inner-tabs-list {
      display: block;
      width: 100%;

      .tab-list {
        display: inline-block;
        margin-bottom: -6px;
        .tab-button {
          font-size: 16px;
          text-transform: capitalize;

          &.Mui-selected {
            color: #434bdf;
          }
        }
      }
    }
  }

  .information-card {
    display: block;
    height: 60vh;
    // min-height: 550px;
    // box-shadow: none;
    // .title {
    //   // display: block;
    //   padding-left: 16px;
    //   padding-right: 16px;
    //   // margin-left: -16px;
    //   // margin-right: -16px;
    //   h5 {
    //     font-size: 20px;
    //     margin-bottom: 16px;
    //   }
    // }

    // .information-card-inner-content {
    //   height: 430px;
    //   overflow: auto;
    //   overflow-y: auto;
    // }
    // .OnBoarding-card-inner-content {
    //   height: 492px;
    //   overflow: auto;
    //   overflow-y: auto;
    // }
    // .upload-photo-inner-section {
    //   min-height: 880px;

    // }

    form {
      .form-group {
        border: none;
        background: none;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }

        label {
          display: block;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .form-control {
          width: 100%;
          font-size: 14px;
          text-transform: capitalize;
          font-weight: 500;
          height: 45px;
          border-radius: 4px;
          color: #000000;
          font-weight: 400;
          margin-bottom: 5px;
          border-color: #434bdf;
          border: 0;
          padding: 0 10px;
          padding-left: 5px;
          padding-right: 15px;
          &.autocomplete-box {
            display: flex;
            .MuiChip-label {
              max-width: 90px !important;
            }
            .autocomplete-selecter {
              display: flex;
              .MuiAutocomplete-tag {
                margin: 0 2px 0 0;
              }
              .MuiInputBase-root {
                padding-top: 8px;
                padding-bottom: 8px;
              }

              .MuiInputBase-input {
                width: fit-content;
                margin-left: -0;
                margin-right: -0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
                padding-bottom: 0;
                line-height: 1.5;
                &.Mui-disabled {
                  color: #000000;
                  -webkit-text-fill-color: #000000;
                }
              }
              p {
                margin: 6px 0 0 0;
              }
            }
          }

          em {
            font-style: normal;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #434bdf !important;
          }
          .MuiInputBase-input {
            // width: 100%;
            margin-left: -5px;
            margin-right: -5px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: 1.5;
            &.Mui-disabled {
              color: #000000;
              -webkit-text-fill-color: #000000;
            }
          }

          .MuiSvgIcon-root {
            color: #434bdf;
          }

          &.multiple-select {
            .MuiCheckbox-root {
              padding: 5px;
              display: inline-block;
            }

            .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
              height: 0;
              min-height: 32px;
              padding: 0;
              color: #000000;
            }
          }
        }

        textarea {
          height: 114px;
          resize: none;
          border: 1px solid #434bdf;
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          width: 100%;
          border-radius: 4px;
          font-family: "Open Sans", sans-serif;
          padding: 15px;
        }
      }
      .video-section {
        padding: 10px;
        border-radius: 3px;
        border: 1px solid #434bdf;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
        .close-icon {
          position: absolute;
          top: 5px;
          right: 5px;
          padding: 3px;
          // border: 1px solid red;
          color: red;
          background: #ffffff;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 4px 14px 0 rgba(0, 0, 0, 0.14);
        }
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-top: 15px;
      .btn-primary {
        display: block;
        min-width: 180px;
      }
      .btn-primary-error {
        display: block;
        min-width: 180px;
      }

      .btn-primary-outlined {
        height: 36px;
        line-height: 34px;
        color: #434bdf;
        border: 1px solid #434bdf;
        background: transparent;
        text-transform: capitalize;
        text-align: center;
        text-decoration: none;
        margin-right: 15px;
      }
      .disabled-btn {
        min-width: 180px;
        height: 36px;
        border: none;
        color: white;
        background: #7b81f7;
        text-transform: capitalize;
        span {
          .MuiCircularProgress-root {
            color: white;
          }
        }
      }
    }
    // .MuiCardActions-root {
    //   .btn-primary-outlined {
    //     height: 36px;
    //     line-height: 34px;
    //     color: #434bdf;
    //     border: 1px solid #434bdf;
    //     background: transparent;
    //     text-transform: capitalize;
    //     text-align: center;
    //     text-decoration: none;
    //   }
    //   .disabled-btn {
    //     height: 36px;
    //     border: none;
    //     color: white;
    //     background: #7b81f7;
    //     text-transform: capitalize;
    //     span {
    //       .MuiCircularProgress-root {
    //         color: white;
    //       }
    //     }
    //   }
    // }
  }
}
@media only screen and (max-width: 767px) {
  .update-information-container {
    .common-service-tabs {
      .MuiTabPanel-root {
        padding: 0;
      }
    }

    .information-card {
      min-height: 100%;
      .buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;

        .btn-primary {
          display: block;
          min-width: 100%;
          margin-bottom: 15px;
        }
        .btn-primary-error {
          display: block;
          min-width: 100%;
        }

        .btn-primary-outlined {
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
        }
        .disabled-btn {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
}
