.dashboard-container {
  width: 100%;
  display: block;
  .survey-cards {
    width: 100%;
    display: block;
    a {
      text-decoration: auto;
      margin-bottom: 20px;
      .survey-card {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        cursor: pointer;
        box-shadow: none;
        border: 1px solid#d2d8dd;
        background: #fdfefe;
        min-height: 150px;
        .survey-title {
          color: #000000;
          width: 100%;
          //min-height: 50px;
        }
        .survey-number {
          font-size: 40px;
          line-height: 1.5;
          font-weight: 600;
          color: #000000;
        }
       
        &.survey-Onboarding-card {
          position: relative;
          // border: none;
        }

        &.survey-Onboarding-card svg {
          position: absolute;
          top: 0;
          left: 0;
        }

        &.survey-Onboarding-card .line {
          stroke-dasharray: 260;
          stroke-width: 5px;
          fill: transparent;
          stroke: #434bdf;
          animation: svgAnimation 20s linear infinite;
        }

        @keyframes svgAnimation {
          from {
            stroke-dashoffset: 0;
          }
          to {
            stroke-dashoffset: 3500;
          }
        }
      }
    }
  }
  .survey-name {
    cursor: pointer;
  }
}
