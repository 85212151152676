.onBoarding-container {
  width: 70%;
  margin: 0 auto;
  display: block;
  .viewQuestion {
    h3 {
      display: block;
      color: #000000;
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 0;
      font-weight: 500;
    }
    .check-box {
      .MuiFormControlLabel-root {
        .MuiSvgIcon-fontSizeMedium {
          fill: #434bdf;
        }
        .MuiFormControlLabel-label{
            color: #000000;
        }
      }
    }
    .radio-buttons {
      .MuiFormControlLabel-root {
        .MuiSvgIcon-fontSizeMedium {
          fill: #434bdf;
        }
        .MuiFormControlLabel-label{
            color: #000000;
        }
      }
    }
    .select-box {
      .MuiOutlinedInput-root {
        height: 48px;
        border-color: #d2d8dd;
      }
    }
    .calender {
      .MuiOutlinedInput-root {
        height: 48px;
        border-color: #d2d8dd;
      }
    }
    .text-box {
      .MuiOutlinedInput-root {
        height: 48px;
        border-color: #d2d8dd;
      }
    }
  }
}

@media only screen and (max-width: 992px){
    .onBoarding-container{
        width: 100%;
    }
}
