@import "https://cdn.jsdelivr.net/gh/hung1001/font-awesome-pro@0ac23ca/css/all.css";

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
}

ul,
li,
ol,
p {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d9da;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c2c2;
}

body {
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  transform-style: preserve-3d;
}

*:focus {
  outline: none;
}

.container-fluid {
  max-width: 100%;
  padding: 0 auto;
  display: table;
}

.btn-primary {
  height: 36px;
  border: none;
  color: #ffffff !important;
  background: #434bdf !important;
  text-transform: capitalize;

  &:hover {
    background: #181e9b !important;
  }

  span {
    .MuiCircularProgress-root {
      color: #fff !important;
    }
  }
}

.btn-primary-outlined {
  height: 36px;
  color: #434bdf !important;
  border-color: #434bdf !important;
  background: transparent;
  text-transform: capitalize;
}

.main-container {
  width: 100%;
  display: block;
  padding: 80px 20px 20px 20px;
}

.Toastify {
  .Toastify__toast-container {
    padding: 0;
    width: auto;
    .Toastify__toast {
      min-height: auto;
      max-width: max-content;

      &.Toastify__toast--error {
        color: #e74c3c;
        background-color: rgb(221, 222, 255);
      }
      &.Toastify__toast--success {
        color: #07bc0c;
        background-color: rgb(221, 222, 255);
      }
      .Toastify--animate-icon {
        display: none;
      }
      .Toastify__close-button {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .Toastify {
    .Toastify__toast-container {
      padding: 0;
      width: 100%;
      .Toastify__toast {
        min-height: auto;
        width: 100%;
        max-width: 100%;
  
        &.Toastify__toast--error {
          color: #e74c3c;
          background-color: rgb(221, 222, 255);
        }
        &.Toastify__toast--success {
          color: #07bc0c;
          background-color: rgb(221, 222, 255);
        }
        .Toastify--animate-icon {
          display: none;
        }
        .Toastify__close-button {
          display: none;
        }
      }
    }
  }
}
