.serverAvailable-container {
  width: 100%;
  display: block;
  .survey-box {
    width: 23.7%;
    height: 150px;
    display: inline-table;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid #d2d8dd;
    background: #fdfefe;
    margin: 8px;
    padding: 12px;
    border-radius: 3px;
    .survey-name {
      width: 100%;
      display: block;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
      color: #000000;
    }
    .survey-description {
      width: 100%;
      display: block;
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      font-family: "Roboto";
    }
  }
}
@media only screen and (max-width: 1280px) {
  .serverAvailable-container {
    .survey-box {
      width: 31.6%;
      height: auto;
      min-height: 100px;
      
    }
  }
}
@media only screen and (max-width: 991px){
  .serverAvailable-container {
    .survey-box {
      width: 31%;
      height: auto;
      min-height: 100px;
      
    }
  }
}

@media only screen and (max-width: 767px) {
  .serverAvailable-container {
    .survey-box {
      width: 100%;
      height: auto;
      min-height: 100px;
      margin: 0 0 20px 0;
    }
  }
}
