.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // padding: 15px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #434bdf;

  .header-inner-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    padding: 15px 20px;
    min-height: 100%;
    color: #fff;
    text-align: center;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 3px 0px;
    background: transparent;
    background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.3));
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
    .icon-btn {
      width: 30px;
      height: 30px;
      border: none;
      display: flex;
      color: #ffffff;
      align-items: center;
      justify-content: center;
      background: transparent;
      margin-right: 8px;
    }
    h3 {
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
    .reload-icon-btn {
      display: none;
      width: 35px;
      min-width: 35px;
      height: 35px;
      padding: 0;
      border-radius: 50%;
      background-color: #ffffff;
      span {
        margin-right: 0;
        .reload-icon {
          font-size: 25px;
          color: #434bdf;
        }
      }
    }
  }
}

.side-drawer {
  position: relative;
  display: block;
  width: 240px;
  height: 100%;
  .drawer-logo {
    width: 180px;
    padding: 10px 16px;
  }
  ul {
    padding-top: 0;
    padding-bottom: 50px;
    li {
      .list-btn {
        color: #000000;
        .MuiListItemIcon-root {
          min-width: 24px;
          margin-right: 10px;
          color: #000000;
        }
        .MuiListItemText-root {
          margin: 0;
          span {
            font-size: 15px;
          }
        }
        &:hover {
          background-color: #3b42c5;
          color: #ffffff;

          .MuiListItemIcon-root {
            min-width: 24px;
            color: #ffffff;
          }
        }
        &.active {
          background-color: #3b42c5;
          color: #ffffff;
          .MuiListItemIcon-root {
            min-width: 24px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .app-versions {
    position: fixed;
    bottom: 0;
    /* left: 20px; */
    padding: 10px 20px;
    width: 240px;
    background: #ffffff;
    a {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #3b42c5;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .header-container {
    .header-inner-container {
      .reload-icon-btn {
        display: block;
      }
    }
  }
}
