.common-modal-container {
    .MuiPaper-root {
      display: block;
      background-color: #fafafb;
      max-width: 500px !important;
      width: 500px !important;
      .MuiDialogContent-root {
        max-height: 350px;
      }
    }
    .heading {
      display: block;
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      color: #333951;
      line-height: 30px;
      margin-bottom: 20px;
    }
    
  
    .MuiDialogActions-root {
      padding: 20px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
     
    }
  }