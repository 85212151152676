.login-main {
  .row-height {
    height: 100vh;
    .content-left {
      background-color: #434bdf;
      padding: 0;
      .content-left-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 100%;
        background-color: #434bdf;
        color: #fff;
        text-align: center;
        position: relative;
        background: transparent;
        background: -webkit-linear-gradient(
          top,
          transparent,
          rgba(0, 0, 0, 0.5)
        );
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
        .banner-content {
          height: auto;
          padding: 35px 90px 35px 90px;
          .login-img {
            width: 80%;
            margin: 0 auto;
          }
          .copy-right {
            color: #ffffff;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.5;
            opacity: 0.8;
            margin-top: 50px;
          }
        }
      }
    }
    .content-right {
      position: relative;
     
      .logo {
        text-align: center;
      }
      h3 {
        padding: 0;
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 20px;
        display: block;
      }
      .form-content-right {
        width: 70%;
        height: 70%;
        margin: auto;
        position: relative;

        .form-group {
          position: relative;
          label {
            font-size: 16px;
            line-height: 1.5;
            font-weight: 400;
            color: #000000;
            margin-bottom: 5px;
          }

          .form-control {
            border: 1px solid #d2d8dd;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            border-radius: 3px;
            font-size: 14px;
            font-size: 0.875rem;
            height: calc(2.55rem + 0px);
            &:focus {
              border-color: #434bdf;
              box-shadow: none;
            }
          }
          span {
            font-size: 13px;
            line-height: 1.5;
            font-weight: 400;
            color: red;
          }
          i {
            position: absolute;
            top: 41.5px;
            right: 10px;
            color: #858789;
            cursor: pointer;
          }
        }
        .form-check {
          padding-top: 0;
          .form-check-input {
            border-radius: 2px;
            height: 20px;
            width: 20px;
            border: 1px solid #d2d8dd;
            &:focus {
              box-shadow: none;
            }
            &:checked {
              background-color: #434bdf;
              border-color: #434bdf;
            }
          }
          .form-check-label {
            display: block;
            position: relative;
            font-size: 14px;
            padding-left: 8px;
            line-height: 1.9;
            cursor: pointer;
            -webkit-user-select: none;
            user-select: none;

            a {
              color: #434bdf;
              text-decoration: none;
            }
          }
        }
        p {
          display: block;
          position: relative;
          font-size: 14px;
          padding-left: 8px;
          line-height: 1.8;
          cursor: pointer;
          -webkit-user-select: none;
          user-select: none;
          a {
            color: #434bdf;
            text-decoration: none;
          }
        }
        .app-versions {
          width: 100%;
          position: absolute;
          bottom: -30px;
          left: 0;
          right: 0;
          a {
            display: block;
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            color: #3b42c5;
            text-decoration: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .login-main {
    .row-height {
      height: auto;
      .content-left {
        display: none;
      }
      .content-right {
        .form-content-right {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .login-main {
    .row-height {
      height: auto;
      .content-right {
        .logo {
          margin: 30px auto;
        }
        .form-content-right {
          width: 100%;
          height: 75vh;
        }
      }
    }
  }
}
